<template>
  <page-container :title="pageTitle" :breadcrumb="breadcrumb">
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="simple-query">
          <a-form-model ref="queryForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="公司/项目" prop="monitorpointname" style="width: 25%">
              <a-cascader :field-names="{ label: 'userdepname', value: 'userdepid', children: 'children' }" :options="userInfo.usertype!='0'?deptOptions.filter(item => item.deptype=='1'):deptOptionsAll.filter(item => item.deptype=='1')" v-model="userdepidCascaderSelected" :load-data="userInfo.usertype!='0'?deptOnLoadChildren:deptOnLoadChildrenAll" placeholder="请选择公司/项目" :change-on-select="true" :allow-clear="false"></a-cascader>
            </a-form-model-item>
            <a-form-model-item label="抄表人" prop="meter_reader" style="width: 25%">
              <a-input v-model="queryParams.meter_reader"></a-input>
            </a-form-model-item>
            <a-form-model-item label="抄表时段" prop="queryInstallDate" style="width: 25%">
              <a-range-picker v-model="queryInstallDate" :default-value="[moment(`${queryParams.starttime.substring(0,10)}`, 'YYYY-MM-DD'), moment(`${queryParams.endtime.substring(0,10)}`, 'YYYY-MM-DD')]"></a-range-picker>
            </a-form-model-item>
            <div class="query-btns" style="float: right;width: 20%">
              <a-button @click="resetQueryParams">重置</a-button>
              <a-button type="primary" class="after-btn" @click="getTableData(true)">查询</a-button>
              <a-button type="link" @click="showAdvanced=!showAdvanced">展开<a-icon :type="showAdvanced?'up':'down'"></a-icon></a-button>
            </div>
            <div v-show="showAdvanced" class="advanced-query">
              <a-form-model-item label="设备编号" prop="equipment_num" style="width: 25%">
                <a-input v-model="queryParams.equipment_num"></a-input>
              </a-form-model-item>
              <a-form-model-item label="设备名称" prop="equipment_name" style="width: 25%">
                <a-input v-model="queryParams.equipment_name"></a-input>
              </a-form-model-item>
              <a-form-model-item label="组名" prop="group_name" style="width: 25%">
                <a-input v-model="queryParams.group_name"></a-input>
              </a-form-model-item>
              <a-form-model-item label="测量类型" prop="measure_type" style="width: 25%">
                <a-select v-model="queryParams.measure_type">
                  <a-select-option v-for="(item, index) in MeasureTypeList" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="警告" prop="warning" style="width: 25%">
                <a-select v-model="queryParams.warning">
                  <a-select-option value="正常">正常</a-select-option>
                  <a-select-option value="警告">警告</a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="表号" prop="meter_num" style="width: 25%">
                <a-input v-model="queryParams.meter_num"></a-input>
              </a-form-model-item>
            </div>
          </a-form-model>
        </div>
      </div>
    </div>
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="table-header">
          <h3 class="table-title">{{pageTitle}}</h3>
          <div class="table-btns">
            <a-button v-if="btnList.includes('导出')" @click="exportModal" style="margin-right: 10px"><a-icon type="export"></a-icon>批量导出</a-button>
            <a-icon type="redo" class="after-btn table-refresh" @click="getTableData()"></a-icon>
          </div>
        </div>
        <a-table :columns="tableColumns" :customRow="customRow" :data-source="tableData" :rowKey="(record,index)=> index" :scroll="{ x: 1500 }" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 20px;" :pagination="false">
          <span slot="meter_reading_time" slot-scope="value">
            <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
              <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
            </a-tooltip>
          </span>
          <span slot="measure_unit" slot-scope="value">
            <span>{{value}}</span>
          </span>
          <span slot="measure_type" slot-scope="value">
            <span>{{value}}</span>
          </span>
          <span slot="measure_range" slot-scope="value,record">
            <span>{{measureRangeHandle(value,record)}}</span>
          </span>
          <span slot="warning" slot-scope="value">
            <a-tag :color="value==='警告'?'red':'green'">{{value}}</a-tag>
          </span>
          <span slot="standard_value" slot-scope="value">
            <span>{{StandardValueMap[value]}}</span>
          </span>
          <span slot="operation" slot-scope="value, record">
            <a-dropdown>
              <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                <a-icon type="menu"/>
              </a>
              <a-menu slot="overlay" @click="operationClick">
                <a-menu-item :key="'detail-'+record.meter_reading_id">详情</a-menu-item>
              </a-menu>
            </a-dropdown>
          </span>
        </a-table>
        <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" show-size-changer @change="pageChange" @showSizeChange="onShowSizeChange" :show-total="(total, measure_range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
          <template slot="buildOptionText" slot-scope="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>
    </div>
<!--    <meter-reading-list :visible.sync="modalVisible" :show-type="modalShowType" :detail-data="modalDetail"></meter-reading-list>-->
    <a-modal :visible="modalVisible" title="抄表数据详情" @cancel="modalVisible=false" :footer="null" :width="800">
      <h4 style="color: red;margin-left: 75px;margin-top: -10px">说明：抄表“警告”规则：</h4>
      <h4 style="color: red;margin-left: 75px;margin-top: -10px">1.标准值有明确区间范围的超出范围则警告；</h4>
      <h4 style="color: red;margin-left: 75px;margin-top: -10px">2.标准值是单方向限制的，超闭端或开端点大于50%则警告；</h4>
      <h4 style="color: red;margin-left: 75px;margin-top: -10px">3.水电气等计量表小于上次抄表值或大于上次抄表值一倍以上则警告。</h4>
      <a-form-model ref="queryForm" :model="modalDetail" layout="inline" class="query-form" :label-col="{span:10}" :wrapper-col="{span:14}">
        <a-form-model-item label="项目名称" prop="monitorpointname" style="width: 45%">
          <a-input v-model="modalDetail.monitorpointname" style="width: 200px;" :disabled="true"></a-input>
        </a-form-model-item>
        <a-form-model-item label="组名" prop="group_name" style="width: 45%">
          <a-input v-model="modalDetail.group_name" placeholder="" style="width: 200px;" :disabled="true"></a-input>
        </a-form-model-item>
        <a-form-model-item label="设备编号" prop="equipment_num" style="width: 45%">
          <a-input v-model="modalDetail.equipment_num" placeholder="" style="width: 200px;" :disabled="true"></a-input>
        </a-form-model-item>
        <a-form-model-item label="设备名称" prop="equipment_name" style="width: 45%">
          <a-input v-model="modalDetail.equipment_name" placeholder="" style="width: 200px;" :disabled="true"></a-input>
        </a-form-model-item>
        <a-form-model-item label="仪表名称" prop="meter_reading_device" style="width: 45%">
          <a-input v-model="modalDetail.meter_reading_device" placeholder="" style="width: 200px;" :disabled="true"></a-input>
        </a-form-model-item>
        <a-form-model-item label="所在机房" prop="machine_room_name" style="width: 45%">
          <a-input v-model="modalDetail.machine_room_name" placeholder="" style="width: 200px" :disabled="true"></a-input>
        </a-form-model-item>
        <a-form-model-item label="表号" prop="meter_num" style="width: 45%">
          <a-input v-model="modalDetail.meter_num" placeholder="" style="width: 200px" :disabled="true"></a-input>
        </a-form-model-item>
        <a-form-model-item label="测量类型" prop="measure_type" style="width: 45%">
          <a-input v-model="modalDetail.measure_type" placeholder="" style="width: 200px" :disabled="true"></a-input>
        </a-form-model-item>
        <a-form-model-item label="标准值" prop="standard_value" style="width: 45%">
          <a-input v-model="StandardValueMap[modalDetail.standard_value]" placeholder="" style="width: 200px" :disabled="true"></a-input>
        </a-form-model-item>
        <a-form-model-item label="范围" prop="measure_range" style="width: 45%">
          <a-input v-model="modalDetail.measure_range" placeholder="" style="width: 200px" :disabled="true"></a-input>
        </a-form-model-item>
        <a-form-model-item label="测量单位" prop="measure_type" style="width: 45%">
          <a-input v-model="modalDetail.measure_type" placeholder="" style="width: 200px" :disabled="true"></a-input>
        </a-form-model-item>
        <a-form-model-item label="实际值" prop="actual_value" style="width: 45%">
          <a-input v-model="modalDetail.actual_value" placeholder="" style="width: 200px" :disabled="true"></a-input>
        </a-form-model-item>
        <a-form-model-item label="警告" prop="warning" style="width: 45%">
          <a-input v-model="modalDetail.warning" placeholder="" style="width: 200px" :disabled="true"></a-input>
        </a-form-model-item>
        <a-form-model-item label="抄表时间" prop="meter_reading_time" style="width: 45%">
          <a-date-picker v-model="modalDetail.meter_reading_time" show-time placeholder="" :disabled="true" style="width: 200px;"></a-date-picker>
        </a-form-model-item>
        <a-form-model-item label="抄表人" prop="meter_reader" style="width: 45%">
          <a-input v-model="modalDetail.meter_reader" placeholder="" style="width: 200px" :disabled="true"></a-input>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </page-container>
</template>
<script>
import moment from "moment";
import {mapGetters, mapState} from "vuex";
import pagination from "@/mixins/pagination";
import {getCache, getItemFromArrayByKey, getTimeRange} from "U/index";
import {deleteMeterReading, exportMeterReadingListByCondition, getMeterReadingListByCondition} from "A/patrol";
import deptselect from "@/mixins/deptselect";
import {getMonitorPointNameListByCondition} from "A/monitoring";
import MeterReadingList from "C/modals/MeterReadingList";
export default {
  name: "meterReadingReport",
  mixins: [deptselect,pagination],
  components:{
    MeterReadingList,
  },
  data() {
    return {
      moment,
      detailVisible:false,
      detailParams:{},
      userdepidCascaderSelected: [],
      tableData: [],
      tableLoading: false,
      showAdvanced: false,
      queryInstallDate:null,
      monitorpointList:[],
      MeasureTypeList:[],
      MeasureUnitList:[],
      StandardValueMap:{},
      queryParams:{
        monitorpointname:'',
        meter_reader:'',
        group_name:'',
        equipment_num:'',
        equipment_name:'',
        starttime:'',
        endtime:'',
        measure_type:'',
        warning:'',
        meter_reading_type:'1',
        meter_num:'',
      },
      tableColumns:[
        {
          title: '项目名称',
          dataIndex: 'monitorpointname',
          key: 'monitorpointname',
          ellipsis: true,
          scopedSlots: { customRender: 'monitorpointname' },
        },
        {
          title: '组名',
          dataIndex: 'group_name',
          key: 'group_name',
          ellipsis: true,
        },
        {
          title: '设备编号',
          dataIndex: 'equipment_num',
          key: 'equipment_num',
          ellipsis: true,
          align:'center',
        },
        {
          title: '设备名称',
          dataIndex: 'equipment_name',
          key: 'equipment_name',
          ellipsis: true,
        },
        {
          title: '仪表名称',
          dataIndex: 'meter_reading_device',
          key: 'meter_reading_device',
          ellipsis: true,
        },
        {
          title: '所在机房',
          dataIndex: 'machine_room_name',
          key: 'machine_room_name',
          ellipsis: true,
        },
        {
          title: '表号',
          dataIndex: 'meter_num',
          key: 'meter_num',
          ellipsis: true,
        },
        {
          title: '测量类型',
          dataIndex: 'measure_type',
          key: 'measure_type',
          ellipsis: true,
          scopedSlots: { customRender: 'measure_type' },
        },
        {
          title: '标准值',
          dataIndex: 'standard_value',
          key: 'standard_value',
          ellipsis: true,
          scopedSlots: { customRender: 'standard_value' },
        },
        {
          title: '范围',
          dataIndex: 'measure_range',
          key: 'measure_range',
          ellipsis: true,
          scopedSlots: { customRender: 'measure_range' },
        },
        {
          title: '测量单位',
          dataIndex: 'measure_unit',
          key: 'measure_unit',
          ellipsis: true,
          scopedSlots: { customRender: 'measure_unit' },
        },
        {
          title: '实际值',
          dataIndex: 'actual_value',
          key: 'actual_value',
          ellipsis: true,
        },
        {
          title: '警告',
          dataIndex: 'warning',
          key: 'warning',
          ellipsis: true,
          scopedSlots: { customRender: 'warning' },
        },
        {
          title: '抄表时间',
          dataIndex: 'meter_reading_time',
          key: 'meter_reading_time',
          ellipsis: true,
          scopedSlots: { customRender: 'meter_reading_time' },
          width: 150
        },
        {
          title: '抄表人',
          dataIndex: 'meter_reader',
          key: 'meter_reader',
          ellipsis: true,
        },
        {
          title: '操作',
          dataIndex: 'operation',
          key: 'operation',
          ellipsis: true,
          align:'center',
          scopedSlots: { customRender: 'operation' },
          width: 70
        },
      ],
      modalVisible:false,
      modalShowType:'detail',
      modalDetail:{},

      //操作按钮权限
      btnList:[],
      menu:{},
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    ...mapGetters(['operationMenuTree']),
    pageTitle() {
      return "抄表数据分析报表"
    },
    breadcrumb() {
      const pages = [{name:"数据分析与报告",path:""}]
      pages.push({name:this.pageTitle,path:""})
      return pages
    },
  },
  watch:{
    queryInstallDate(val) {
      let {start, end} = getTimeRange(val);
      this.queryParams.starttime = start;
      this.queryParams.endtime = end;
    },
    userdepidCascaderSelected(val) {
      if(val && val.length) {
        this.queryParams.userdepsid = val[val.length-1];
      }else {
        this.queryParams.userdepsid = '';
      }
    },
  },
  created() {
    this.menu=getItemFromArrayByKey(this.operationMenuTree,"menuid",this.$route.params.menuid,"children")
    for(let i=0;i<this.menu.children.length;i++){
      this.btnList.push(this.menu.children[i].menuname)
    }
    let time = new Date();
    let monthNum = moment(time).format("YYYY-MM").slice(5);
    this.queryParams.starttime = moment(time).month(monthNum - 1).date(1).startOf("month").format("YYYYMMDD");
    this.queryParams.endtime = moment(time).month(monthNum - 1).date(1).endOf("month").format("YYYYMMDD");
    let logininfo = getCache('logininfo', true);
    if (logininfo) {
      this.usertype=logininfo.usertype;
    }
    let dictionary = getCache('dictionary', true);
    if(dictionary) {
      this.MeasureTypeList = dictionary.MeasureTypeList;
      this.MeasureUnitList = dictionary.MeasureUnitList;
      this.StandardValueMap = dictionary.StandardValueMap;
    }
    this.getTableData()
    this.initDeptOptionsAll();
    this.getMonitorPointNameList();
  },
  methods:{
    getMonitorPointNameList(){
      getMonitorPointNameListByCondition({userdepsid:this.userInfo.userdepid}).then(res=>{
        if(res&&res.returncode=='0'){
          this.monitorpointList=res.item
        }
      })
    },
    getTableData(firstPage) {
      if(firstPage) {
        this.currentPage = 1;
      }
      this.tableLoading = true;
      let params = {
        ...this.queryParams,
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize
      };
      getMeterReadingListByCondition(params).then(res => {
        this.tableLoading = false;
        if(res && res.returncode == '0') {
          this.tableData = res.item;
          this.pagination.total = res.count;
        }
      }).catch(err => {
        this.tableLoading = false;
      })
    },
    customRow(record, index){
      return {
        on: { // 事件
          click: () => {
            record.meter_reading_time = record.meter_reading_time ? moment(record.meter_reading_time, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : null
            this.modalDetail = record
            this.modalVisible = true
          },
        },
      }
    },
    measureRangeHandle(value,record){
      if(record.standard_value==='='||record.standard_value==='>'||record.standard_value==='≥'||record.standard_value==='<'||record.standard_value==='≤'){
        return record.standard_value+value
      }else if(record.standard_value==='≥≤'){
        return "["+value+"]"
      }else if(record.standard_value==='><'){
        return "("+value+")"
      }else if(record.standard_value==='≥<'){
        return "["+value+")"
      }else if(record.standard_value==='>≤'){
        return "("+value+"]"
      }else if(record.standard_value==='<>'){
        return "<"+value.split(",")[0]+","+">"+value.split(",")[1]
      }else if(record.standard_value==='≤≥'){
        return "≤"+value.split(",")[0]+","+"≥"+value.split(",")[1]
      }
    },
    resetQueryParams() {
      this.queryInstallDate = [];
      this.userdepidCascaderSelected=[]
      this.$refs.queryForm.resetFields();
    },
    operationClick({key}){
      let arr = key.split('-');
      let type = arr[0];
      let meter_reading_id = arr[1];
      let record = getItemFromArrayByKey(this.tableData, 'meter_reading_id', meter_reading_id);
      if(type == 'delete') {
        this.deleteConfirm(meter_reading_id, record)
      }else if(type == 'detail'){
        record.meter_reading_time=record.meter_reading_time?moment(record.meter_reading_time,'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss'):null
        this.modalDetail=record
        this.modalShowType='detail'
        this.modalVisible=true
      }
    },
    deleteConfirm(value, record) {
      this.$confirm('确定要删除该数据？',{
        type:'warning',
        centered:true,
      }).then(()=>{
        this.delete(record.meter_reading_id);
      }).catch(()=>{
      });
    },
    delete(meter_reading_id) {
      this.showLoading();
      if(meter_reading_id) {
        let params = {
          meter_reading_id
        };
        deleteMeterReading(params).then(res => {
          this.hideLoading();
          if(res && res.returncode == '0') {
            this.$message.success('操作成功');
            this.getTableData();
          }else {
            this.$message.error(res.errormsg||'操作失败');
          }
        })
      }
    },
    deleteBatch() {
      if(this.batchrowids) {
        this.deleteBatchConfirm();
      }else {
        this.$message.warning('请先选择删除项');
      }
    },
    deleteBatchConfirm() {
      this.$confirm('确定要删除选中数据？',{
        type:'warning',
        centered:true,
      }).then(()=>{
        this.delete(this.batchrowids);
      }).catch(()=>{
      });
    },
    exportModal(){
      this.$confirm('你确认要按照所选筛选条件导出文件吗？',{
        type:'warning',
        centered:true,
      }).then(()=>{
        this.exportMeterReading()
      }).catch(()=>{
      });
    },
    exportMeterReading(){
      let params = {
        ...this.queryParams,
      }
      this.showLoading();
      exportMeterReadingListByCondition(params).then((res)=> {
        this.hideLoading();
        this.$message.success("导出成功")
      }).catch(()=>{
        this.hideLoading();
      })
    }
  },
}
</script>
<style scoped>

</style>